export class LocalStorageDAO {

  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  public get(): string {
    return localStorage.getItem(this.key);
  }

  public getParsed(): any {
    return JSON.parse(this.get());
  }

  public set(data: string): void {
    localStorage.setItem(this.key, data);
  }

  public setObject(object: object): void {
    localStorage.setItem(this.key, JSON.stringify(object));
  }

  public delete(): void {
    localStorage.removeItem(this.key);
  }

  public isEmpty(): boolean {
    return !this.get();
  }
}
