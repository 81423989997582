import { ApiConfig, AppAPI } from './app.config.interface';

/**
 * API Config
 *
 * Constante com propriedades relacionadas com cada
 * feature ou recurso. Cada propriedade é um cast do tipo
 *  `AppAPIConfig` que contém propriedades de métodos RESTful.
 */
// export const APIConfig: ApiConfig = new ApiConfig();
export const APIConfig = {
    ACCOUNT: {
        POST: () =>
            '/v1/accounts/logiin'
    } as AppAPI,
    PARAMETERS_VEHICLE_CAPACITY: {
        GET_ALL: () => '/v2/admin/parameters_vehicle_capacity',
        PUT: () => '/v2/admin/parameters_vehicle_capacity',
    } as AppAPI,
    PARAMETERS_FREIGHT_PRICE: {
        GET_ALL: () => '/v2/admin/parameters_delivery_value',
        PUT: () => '/v2/admin/parameters_delivery_value',
    } as AppAPI,
} as ApiConfig;
