import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { GraphQlService } from '@shared/services/graphql/graphql.service';
import { APP_CONFIG, APP_DI_CONFIG } from '@core/config/app.config.constant';

@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initFunction,
      deps: [GraphQlService],
      multi: true
    },
    {
      provide: APP_CONFIG,
      useValue: APP_DI_CONFIG
    }
  ]
})
export class GraphQLModule {
  constructor() {}
}

export function initFunction(graphqlService: GraphQlService): any { return (): void => graphqlService.initApollo(); }
