import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {AuthService} from '@core/services/auth/auth.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        const hasError = route.queryParams['error'];
        const hasEmail = route.queryParams['email'];

        if (!hasError) {
            this.router.navigate(['/login'], {queryParams: route.queryParams});
            return false;
        }
        
        this.router.navigate([ '/unauthorized'], { queryParams: { error: hasError, email: hasEmail } });
        return false;

    }
}
