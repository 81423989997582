export interface UserLoginApiInterface {
  id?: string;
  email?: string;
  token?: string;
  name?: string;
  policy_type?: string;
}
interface UserInterface {
  id?: string;
  email?: string;
  token?: string;
  name?: string;
  policyType?: string;
}

export class User implements UserInterface {
  public id?: string;
  public email?: string;
  public token?: string;
  public name?: string;
  public policyType?: string;
  constructor({
    id,
    email,
    token,
    name,
    policyType
  }: UserInterface) {
    this.id = id;
    this.email = email;
    this.token = token;
    this.name = name;
    this.policyType = policyType;
  }
}
