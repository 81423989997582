import {Injectable, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs';

import {environment} from 'environments/environment';
import {AccountPolicyType, LoginTypeEnum} from '@shared/enums/common.enums';
import {APP_CONFIG} from '@core/config/app.config.constant';
import {AppConfig} from '@core/config/app.config.interface';
import {LocalStorageDAO} from '@shared/models/DAO/localStorageDAO.model';
import {LocalStorageKeys} from '@shared/models/DAO/localStorageKeysDAO.model';
import {EnvironmentInterface} from '@shared/models/environment/environment.model';
import {User, UserLoginApiInterface} from '@shared/models/user/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userDAO = new LocalStorageDAO(LocalStorageKeys.USER);
    private profileDAO = new LocalStorageDAO(LocalStorageKeys.PROFILE);
    private accountStatusDAO = new LocalStorageDAO(LocalStorageKeys.ACCOUNT_STATUS);
    private env = (environment as EnvironmentInterface);
    private ssoEnabled = this.env.ssoEnabled;

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
    }

    /**
     * User login
     *
     * @param {*} loginParam
     * @returns {Observable<User>}
     * @memberof AuthService
     */
    public login(loginParam: { email: string, password: string }): Observable<UserLoginApiInterface> {
        this.clearLocalStorage();

        this.loginTypeSet(LoginTypeEnum.EMAIL);

        const url = this.config.API.ACCOUNT.POST();

        return this.http.post<User>(url, loginParam);
    }

    /**
     * Remove user data from localstorage, SSO and redirects to login page
     *
     * @memberof AuthService
     */
    public logout(): void {
        const user: User = this.userDAO.getParsed();

        const loginType = localStorage.getItem('loginType');

        this.clearLocalStorage();

        if (this.ssoEnabled && loginType === LoginTypeEnum.SSO) {

            const url = user ? this.ssoLogoutHandlerUrl(user.name) : '';

            if (url) {
                this.document.location.href = url;
            }
        } else {
            this.redirectToLogin();
        }
    }


    /**
     * Remove user data from localstorage, SSO and redirects to login page per Email
     *
     * @memberof AuthService
     */
    public logoutPerEmail(email: string): void {
        
        this.clearLocalStorage();

        const url = email ? this.ssoLogoutHandlerUrl(email) : '';

        if (url) {
            this.document.location.href = url;
        }
    }

    /**
     * Returns if user is logged in
     *
     * @returns {boolean}
     * @memberof AuthService
     */
    public isLoggedIn(): boolean {
        this.ssoLoginIsValidAndSave();
        const user: User = this.userDAO.getParsed();

        return !!(user && user.token);
    }

    /**
     * Returns true if the role is the same as the account
     *
     * @public
     * @param {AccountPolicyType} policyType
     * @returns {boolean}
     * @memberof AuthService
     */
    public hasRole(policyType: AccountPolicyType): boolean {
        const user: User = this.userDAO.getParsed();

        return this.isLoggedIn() && window.atob(user.policyType) === policyType;
    }

    /**
     * Returns true if the role is the same as the type
     *
     * @public
     * @param {boolean} userType
     * @returns {boolean}
     * @memberof AuthService
     */
    public hasType(userType: boolean): boolean {
        const user: User = this.userDAO.getParsed();

        return this.isLoggedIn() && window.atob(user.policyType) === AccountPolicyType.MAESTRO && userType;
    }

    /**
     * Goes to home page
     *
     * @public
     * @memberof AuthService
     */
    public redirectToHome(): void {
        this.router.navigateByUrl('/pedidos');
    }

    /**
     * Goes to login page
     *
     * @public
     * @memberof AuthService
     */
    public redirectToLogin(): void {
        this.router.navigateByUrl('/login');
    }

    public ssoLogin(): void {
        this.clearLocalStorage();

        this.loginTypeSet(LoginTypeEnum.SSO);

        if (this.ssoEnabled) {

            const url = this.ssoLoginHandlerUrl();

            if (url) {
                this.document.location.href = url;
            } else {
                throw new Error('Não foi possível realizar o redirecionamento para o login.');
            }
        }
    }

    public setDefinitiveToken(loginParam: { request_code: string, transient_token: string }): Observable<any> {
        const url = this.ssoLLoginHandlerUrlToken();

        return this.http.post(url, JSON.stringify(loginParam), {headers: {'Content-Type': 'application/json'}});

    }

    /**
     * Set login type
     *
     * @param type - LoginTypeEnum (EMAIL or SSO)
     *
     */
    private loginTypeSet(type: LoginTypeEnum): void {
        localStorage.setItem('loginType', type);
    }

    /**
     * Clear keys [ USER, PROFILE, ACCOUNT_STATUS ] from local storage
     *
     * @private
     */
    private clearLocalStorage(): void {
        this.accountStatusDAO.delete();
        this.userDAO.delete();
        this.profileDAO.delete();
        localStorage.clear();
    }

    /**
     * Returns the logout url for sso, if the email is valid
     *
     * @private
     * @param {string} emailInput - email to logout
     * @memberof AuthService
     * @returns {string} url to logout or empty string
     */
    private ssoLogoutHandlerUrl(emailInput: string): string {
        const url = emailInput && this.isEmail(emailInput) ?
            `${this.env.awsUrl}/saml/orkestra/logout?slo=true&name_id=${emailInput}`
            : '';

        return url ? url : this.ssoLoginHandlerUrl();
    }

    /**
     * Returns the login url for sso
     *
     * @private
     * @memberof AuthService
     * @returns {string} url to logout or empty string
     */
    private ssoLoginHandlerUrl(): string {
        const generatedRequestCode = this.generateRandomCode();
        localStorage.setItem('requestCode', generatedRequestCode);
        return this.env.awsUrl + `/saml/orkestra/sso?request_code=${generatedRequestCode}`;
    }

    /**
     * Returns the definitive token url for sso
     *
     * @private
     * @memberof AuthService
     * @returns {string} url to definitive token
     */
    private ssoLLoginHandlerUrlToken(): string {
        return this.env.awsUrl + '/saml/orkestra/auth';
    }

    /**
     * Get params from url and save to local storage
     *
     * @public
     * @memberof AuthService
     */
    private ssoLoginIsValidAndSave(): void {

        const loginType = localStorage.getItem('loginType');
        if (loginType && loginType === LoginTypeEnum.EMAIL) {
            return;
        }

        const urlParams = new URLSearchParams(this.document.location.search);
        const token = urlParams.get('auth');
        const name = urlParams.get('name') && urlParams.get('name').replace('+', ' ');
        const id = urlParams.get('account_id');
        const requestCode = localStorage.getItem('requestCode');

        if (token && requestCode) {
            this.setDefinitiveToken({request_code: requestCode, transient_token: token})
                .subscribe({
                    next: (res: any) => {
                        const definitiveToken = res.token;
                        const policyType = window.btoa(res.policy_type);

                        this.userDAO.setObject(
                            new User({token: definitiveToken, name, id, policyType}));

                        localStorage.removeItem('requestCode');
                        this.redirectToHome();
                    },
                    error: (err: any) => {
                        console.log(err);
                        this.logout();
                    }
                });
        }
    }

    /**
     * Email is valid or not
     *
     * @public
     * @param {string} emailInput - email for validate
     * @memberof AuthService
     * @returns {boolean} true if email is valid
     */
    private isEmail(emailInput: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(emailInput);
    }

    /**
     * Generate random code custom length
     *
     * @public
     * @param {number} length - length of code
     * @memberof AuthService
     * @returns {string} code random
     */
    private generateRandomCode(length: number = 6): string {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.random() * charactersLength);
        }
        return result;
    }
}
