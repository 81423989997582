export const environment = {
    name: 'hlg',
    version: 'HLG',
    production: false,
    graphQlUrl: 'https://james-api-hlg.backoffice.gpa.digital/graphql',
    apiUrl: 'https://james-api-hlg.backoffice.gpa.digital/api',
    localStorage: 'user',
    ssoEnabled: true,
    awsUrl: 'https://james-api-hlg.backoffice.gpa.digital',
    mapUrl: 'https://james-dashboard-map-hlg.backoffice.gpa.digital',
};
