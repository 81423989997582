export enum OrderStatus {
  DID_NOT_ORDER = 0,
  CHECKOUT = 1,
  ACCEPTED = 2,
  ASSIGNED = 3,
  PICKED_UP = 4,
  ARRIVED = 5,
  DELIVERED = 6
}

export enum OrderStatusTextEnum {
  'Cancelado' = 0,
  'Não alocado' = 1,
  'Aceito' = 2,
  'Alocado' = 3,
  'Em transporte' = 4,
  'Chegou' = 5,
  'Entregue' = 6
}

export enum OrderPendencyTextEnum {
  'delay' = 'Atraso',
  'allocation' = 'Alocação',
  'acceptance' = 'Aceite',
  'collection' = 'Coleta',
  'delivery' = 'Entrega',
  'duplication' = 'Pedido Duplicado',
  'todas' = 'Todas'
}

export enum typeOfOrderTextEnum {
  'Convencional' = 0,
  'Express' = 1,
  'Agendado' = 2,
  'Prime' = 3,
  'GPA' = 4,
  // 'Todos' = 5
}

export enum TransportTypeEnum {
  NOT_INFORMED = 0,
  BICYCLE = 1,
  MOTORCYCLE = 2,
  CAR = 3,
  UTILITY = 4
}

export enum TransportType {
  'Não informado' = 0,
  'Bicicleta' = 1,
  'Moto' = 2,
  'Carro' = 3,
  'Utilitário' = 4
}

export enum AgentStatusTypes {
  DECLINED = 'declined',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  ASSIGNED = 'assigned',
  COLLECTED = 'collected',
  FINISHED = 'finished',
  DENIED = 'denied',
  CANCELLED = 'cancelled',
}

export enum AgentTitleTypes {
  ESTABLISHMENT = 'estabelecimento',
  SHOPPER = 'shopper',
  COURIER = 'entregador',
  CLIENT = 'cliente'
}

export enum OrderStatusTimes {
  ARRIVED = 'arrivedAt',
  ASSIGNED = 'assignedAt',
  DELIVERED = 'deliveredAt',
  PICKED_UP = 'pickedUpAt',
  CHECKED_OUT = 'checkedOutAt',
  ACCEPTED = 'acceptedAt'
}

export enum AccountStatusTypes {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BANNED = 'banned'
}

export enum AccountPolicyType {
  MAESTRO = 'maestro_logistica',
  MAESTRO_FRAUDE = 'maestro_fraude',
  MAESTRO_B2B = 'encantador_b2b',
  ENCANTADOR_CX = '',
  SUPERVISOR_MAESTRO = 'supervisor_maestro',
  SUPERVISOR_FRAUDE = 'supervisor_fraude',
  DEV = 'dev'
}

export enum PriceChangeStatus {
  UNSOLICITED = 'unsolicited',
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  DENIED = 'denied'
}

export enum ShopperRequestStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected'
}

export enum ShopperRequestKind {
  ORDER_SPLIT = 'order_split',
  ORDER_CANCELLATION = 'order_cancellation',
  SHOPPER_TRANSFER ='shopper_transfer'
}

export enum WorkShift {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  NIGHT = 'night',
  OVERNIGHT = 'overnight'
}

export enum MissingItemOptionEnum {
  UNINFORMED = 'Cliente não informou o que fazer',
  ACCEPT = 'Aceita sugestão do estabelecimento',
  CANCEL_ITEM = 'Cancelar item faltante',
  CANCEL_ALL = 'Cancelar todo o pedido'
}

export enum EstablishmentFormatEnum  {
  FLASH = 'flash',
  EXPRESS = 'express',
}

export enum LoginTypeEnum {
    EMAIL = 'email',
    SSO = 'SSO'
}