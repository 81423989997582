import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) { }

  /**
   * @param {string} message
   * @param {string} [closeButon='Fechar']
   * @param {boolean}
   * @memberof SnackBarService
   */
  public handleSnackBar(message: string, erro: boolean = true, closeButon: string = 'x', duration: number = 3000): void {
    this.snackBar.open(message, closeButon, {
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration,
      panelClass: erro ? ['msg-erro'] : ['msg-sucess']
    });
  }
}
