import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginModule} from '@modules/login/login.module';
import {MainModule} from '@modules/main/main.module';
import {AuthGuard} from '@core/services/guard/auth.guard';
import {UnauthorizedModule} from '@modules/unauthorized/unauthorized.module';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: (): Promise<MainModule> => import('./modules/main/main.module').then(m => m.MainModule)
    },
    {
        path: 'login',
        loadChildren: (): Promise<LoginModule> => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'unauthorized',
        loadChildren: (): Promise<UnauthorizedModule> => import('./modules/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
