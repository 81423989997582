import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { LocalStorageDAO } from '@shared/models/DAO/localStorageDAO.model';
import { LocalStorageKeys } from '@shared/models/DAO/localStorageKeysDAO.model';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  public errorMessage: string = '';
  private userDAO = new LocalStorageDAO(LocalStorageKeys.USER);
  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  /**
   *
   * Generic method for error handling
   *
   * @public
   * @memberof ErrorHandlerService
   * @param {HttpErrorResponse} httpError
   */
  public handleError(httpError: HttpErrorResponse): string {
    const status = httpError.status;
    const errorStatus = httpError.error.status;

    if ([401, 403].includes(status)) {
      return this.handleError401();
    } else if ([500, 502, 503].includes(status)) {
      return this.handleError500();
    } else if (status === 0) {
      return this.handleConnectionError();
    } else if (status === 400 && errorStatus === 4) {
      this.handleUserDeleted();
      return this.errorMessage = httpError.error.error;
    } else {
      return this.handleOtherError();
    }
  }

  /**
   * To reference errors on the cliente
   *
   * @public
   * @memberof ErrorHandlerService
   */
  public handleError401(): string {
    this.authService.redirectToLogin();
    this.userDAO.delete();
    return this.errorMessage = 'Não Autorizado';
  }

  /**
   * To reference errors on the server
   *
   * @public
   * @memberof ErrorHandlerService
   */
  public handleError500(): string {
    return this.errorMessage = 'Erro no servidor.';
  }

  /**
   * To reference connection errors
   *
   * @public
   * @memberof ErrorHandlerService
   */
  public handleConnectionError(): string {
    return this.errorMessage = 'Ops! Ocorreu um erro no sistema, tente novamente.';
  }

  /**
   * Generic method for error handling
   *
   * @public
   * @memberof ErrorHandlerService
   */
  public handleOtherError(): string {
    return this.errorMessage = 'Ocorreu um erro, tente novamente.';
  }

  /**
   * To reference user not found
   *
   * @public
   * @memberof ErrorHandlerService
   */
  public handleUserDeleted(): void {
    this.router.navigate(['login']);
  }
}
