import { InjectionToken } from '@angular/core';

import { AppConfig } from './app.config.interface';
import { APIConfig } from './api.config.constant';

/**
 * App Config
 *
 * O app config é definido como uma dependência sem classe
 * que faz uso de uma classe InjectionToken. Isso nos permite
 * registrar a constante APP_CONFIG como um provider. Esta
 * é uma solução documentada para criação de configs.
 */
export const APP_DI_CONFIG: AppConfig = {
  API: APIConfig
};

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');
