import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {retry, tap} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {EnvironmentInterface} from '@shared/models/environment/environment.model';
import {User} from '@shared/models/user/user.model';
import {LocalStorageDAO} from '@shared/models/DAO/localStorageDAO.model';
import {LocalStorageKeys} from '@shared/models/DAO/localStorageKeysDAO.model';
import {ErrorHandlerService} from '@shared/services/error-handler/error-handler.service';
import {SnackBarService} from './../services/snackbar/snackbar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private env = (environment as EnvironmentInterface);
    private user: User;
    private localStorage = new LocalStorageDAO(LocalStorageKeys.USER);

    constructor(
        private handleErrorService: ErrorHandlerService,
        private snackBar: SnackBarService,
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let req: HttpRequest<any> = request;
        const baseUrl = this.env.apiUrl;
        
        this.user = this.localStorage.getParsed();
        
        const isUrlContainsSaml = request.url.includes('/saml/');
        
        req = isUrlContainsSaml ? req
            : this.user ? req.clone({
                    setHeaders: {Authorization: this.user.token},
                    url: `${req.url}`
                })
                : req.clone({url: `${baseUrl}${req.url}`});

        return next.handle(req)
            .pipe(
                retry(1),
                tap((event: HttpEvent<any>) => event,
                    (err: HttpErrorResponse) => this.handleHttpErrors(err)));
    }

    /**
     * Get the error and send to ErrorHandlerService
     *
     * @private
     * @param {HttpErrorResponse} err
     * @memberof AuthInterceptor
     */
    private handleHttpErrors(err: HttpErrorResponse): void {
        const msg = this.handleErrorService.handleError(err);
        this.snackBar.handleSnackBar(msg);
    }
}
